<template>
  <ReportTable
    name="Injests"
    item-key="id"
    :headers="headers"
    :filter-fields="filters"
    :provider="ingestAPI.assets360.get360Assets"
    :copy-to-clipboard="false"
    :hide-show="false"
  >
    <template #actions-left>
      <ERow justify="start" align="center">
        <v-btn icon color="primary" @click="refreshTable()"
          ><v-icon>fa-rotate-right</v-icon></v-btn
        >
        <div class="body-1 text--secondary my-3">
          Queued 360s ({{ queue360Size }}) | Total Queued ({{ totalQueueSize }})
        </div>
      </ERow>
    </template>
    <template #actions-right>
      <ERow class="mr-1">
        <v-spacer />
        <v-btn
          small
          color="primary"
          class="text-center ml-2"
          @click="assets360Dialog = true"
        >
          New {{ assets360UploadType }}
        </v-btn>
        <CreateUploadDialog
          :upload-dialog="assets360Dialog"
          :ingest-upload-type="assets360UploadType"
          :provider="generate360UploadLink"
          :upload-object="uploadObject"
          @close-dialog="on360UplaodDialogClosed"
        >
          <template v-if="!uploadObject" #form-append>
            <ValidationProvider
              v-slot="{ errors }"
              name="Date"
              rules="required"
            >
              <v-menu
                v-model="datePickerMenu"
                :close-on-content-click="false"
                :nudge-top="30"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="currentSelectedDate"
                    label="Date"
                    class="mt-5"
                    readonly
                    :error-messages="errors"
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="currentSelectedDate"
                  light
                  @input="datePickerMenu = false"
                />
              </v-menu>
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors }"
              name="Floor"
              rules="required"
            >
              <v-text-field
                v-model="currentSelectedFloor"
                label="Floor"
                outlined
                dense
                :error-messages="errors"
              />
            </ValidationProvider>
          </template>
        </CreateUploadDialog>
      </ERow>
    </template>
    <template #item.videos="{ item }">
      <ERow justify="center" align="center">
        <v-btn
          v-if="!item.videos || item.videos.length <= 0"
          icon
          color="primary"
          target="_blank"
          @click="openUploadDialog(item, assetTypeOf360.Video)"
        >
          <v-icon color="primary"> fa fa-external-link-alt </v-icon>
        </v-btn>
        <ArchiveDownloadLabel
          v-else
          :dataId="item.id"
          :dataType="assetTypeOf360.Video"
          :download-archive="ingestAPI.assets360.getArchive"
        />
      </ERow>
    </template>
    <template #item.images="{ item }">
      <ERow justify="center" align="center">
        <v-btn
          v-if="!item.images || item.images.length <= 0"
          icon
          color="primary"
          target="_blank"
          @click="openUploadDialog(item, assetTypeOf360.Image)"
        >
          <v-icon color="primary"> fa fa-external-link-alt </v-icon>
        </v-btn>
        <ArchiveDownloadLabel
          v-else
          :dataId="item.id"
          :dataType="assetTypeOf360.Image"
          :download-archive="ingestAPI.assets360.getArchive"
        />
      </ERow>
    </template>
    <template #item.model="{ item }">
      <ERow justify="center" align="center">
        <v-btn
          v-if="!item.model"
          icon
          color="primary"
          target="_blank"
          @click="openUploadDialog(item, assetTypeOf360.Model)"
        >
          <v-icon color="primary"> fa fa-external-link-alt </v-icon>
        </v-btn>
        <ArchiveDownloadLabel
          v-else
          :dataId="item.id"
          :dataType="assetTypeOf360.Model"
          :download-archive="ingestAPI.assets360.getArchive"
        />
      </ERow>
    </template>
    <template #item.route="{ item }">
      <ERow justify="center" align="center">
        <v-btn
          v-if="!item.route"
          icon
          color="primary"
          target="_blank"
          @click="openUploadDialog(item, assetTypeOf360.Route)"
        >
          <v-icon color="primary"> fa fa-external-link-alt </v-icon>
        </v-btn>
        <ArchiveDownloadLabel
          v-if="item.route"
          :dataId="item.id"
          :dataType="assetTypeOf360.Route"
          :download-archive="ingestAPI.assets360.getArchive"
        />
      </ERow>
    </template>
    <template #item.mobilePhotos="{ item }">
      <ERow justify="center" align="center">
        <v-btn
          v-if="!item.mobilePhotos || item.mobilePhotos.length === 0"
          icon
          color="primary"
          target="_blank"
          @click="openUploadDialog(item, assetTypeOf360.MobilePhoto)"
        >
          <v-icon color="primary"> fa fa-external-link-alt </v-icon>
        </v-btn>
        <ArchiveDownloadLabel
          v-else
          :dataId="item.id"
          :dataType="assetTypeOf360.MobilePhoto"
          :download-archive="ingestAPI.assets360.getArchive"
        />
      </ERow>
    </template>
    <template #item.mobileAudios="{ item }">
      <ERow justify="center" align="center">
        <v-btn
          v-if="!item.mobileAudios || item.mobileAudios.length === 0"
          icon
          color="primary"
          target="_blank"
          @click="openUploadDialog(item, assetTypeOf360.MobileAudio)"
        >
          <v-icon color="primary"> fa fa-external-link-alt </v-icon>
        </v-btn>
        <ArchiveDownloadLabel
          v-else
          :dataId="item.id"
          :dataType="assetTypeOf360.MobileAudio"
          :download-archive="ingestAPI.assets360.getArchive"
        />
      </ERow>
    </template>
    <template #item.delete="{ item }">
      <ERow justify="center" align="center">
        <v-btn
          icon
          color="primary"
          target="_blank"
          @click="openDeleteDialog(item)"
        >
          <v-icon color="red"> fas fa-trash </v-icon>
        </v-btn>
      </ERow>
    </template>
  </ReportTable>
</template>

<script>
import CreateUploadDialog from "@/components/ingest/uploadDialogs/CreateUploadDialog"
import ArchiveDownloadLabel from "@/components/ingest/ArchiveDownloadLabel"
import ReportTable from "@/components/ReportTable"
import { HEADERS_360 } from "@/components/ingest/ingestHeaders"
import { FILTERS_ASSETS_360 } from "@/components/ingest/ingestFilters"
import { _360AssetType } from "@evercam/shared/types"
import { INGEST_UPLOADS_TYPES } from "@evercam/shared/constants/ingest"
import { IngestApi } from "@evercam/shared/api/ingestApi"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"

export default {
  components: {
    CreateUploadDialog,
    ReportTable,
    ArchiveDownloadLabel,
  },
  data: () => ({
    headers: HEADERS_360,
    filters: FILTERS_ASSETS_360,
    assets360Dialog: false,
    assets360UploadType: INGEST_UPLOADS_TYPES._360Assets,
    assetTypeOf360: _360AssetType,
    uploadObject: undefined,
    datePickerMenu: false,
    currentSelectedDate: "",
    currentSelectedFloor: "",
    queue360Size: "...",
    totalQueueSize: "...",
  }),
  computed: {
    ...mapStores(useAccountStore),
    ingestAPI() {
      return IngestApi
    },
  },
  async mounted() {
    await this.refreshTable()
  },
  methods: {
    async openDeleteDialog(deletedItem) {
      if (
        await this.$confirmDialog.open({
          title: "Delete 360 Asset upload",
          message:
            "Are you sure you want to delete this 360 Asset upload ?. If you are not from the 3D team please contact them before doing this action",
        })
      ) {
        this.delete360Upload(deletedItem.id)
      }
    },
    async delete360Upload(id) {
      try {
        await IngestApi.assets360.deleteUpload(id)
        this.$notifications.success("Successfully deleted entry")
      } catch (e) {
        this.$notifications.error({ text: "Couldn't delete entry", error: e })
      } finally {
        this.refreshTable()
      }
    },
    async generate360UploadLink(payload) {
      try {
        const date = new Date(this.currentSelectedDate)
        const response = await IngestApi.assets360.createUpload(
          payload.project.exid,
          {
            date: `${date.getDate()}-${
              date.getMonth() + 1
            }-${date.getFullYear()}`,
            floor: this.currentSelectedFloor.replace(/[.$/[\]#]/g, " "), // Replace . $ / [ ] # with space
            uploadedBy: this.accountStore.email,
          }
        )
        this.uploadObject = {
          ...response,
          uploadType: _360AssetType.Image,
        }
        this.$setTimeout(() => {
          this.refreshTable()
          this.on360UplaodDialogClosed()
        }, 10)
      } catch (e) {
        this.$notifications.error({ text: "Couldn't get token : ", error: e })

        return ""
      }
    },
    openUploadDialog(item, uploadType) {
      this.uploadObject = {
        ...item,
        uploadType,
      }
      this.assets360Dialog = true
    },
    on360UplaodDialogClosed() {
      this.uploadObject = undefined
      this.assets360Dialog = false
      this.currentSelectedDate = ""
      this.currentSelectedFloor = ""
    },
    async refreshTable() {
      this.$root.$emit("refresh-report-table")
      this.queue360Size = "..."
      this.totalQueueSize = "..."
      this.queue360Size = await this.ingestAPI.assets360.get360QueueSize()
      if (this.queue360Size) {
        this.queue360Size = this.queue360Size.size
      }
      this.totalQueueSize = await this.ingestAPI.getTotalQueueSize()
      if (this.totalQueueSize) {
        this.totalQueueSize = this.totalQueueSize.size
      }
    },
  },
}
</script>
