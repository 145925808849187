var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"value":_vm.uploadDialog,"width":"500"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('v-card',[_c('v-card-title',[_vm._v("\n        "+_vm._s(_vm.title)+"\n        "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c('v-icon',{attrs:{"color":"primary","large":""}},[_vm._v(" fas fa-times ")])],1)],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),(!_vm.uploadObject)?_c('v-card-text',[_c('ERow',{attrs:{"no-gutters":"","justify":"between","align-content":"center"}},[_c('ECol',{staticClass:"pa-0"},[_c('Autocomplete',{attrs:{"item-value":"id","item-text":"name","clearable":"","label":"Project","no-filter":"","cache-items":false,"resource":"projects","rules":"required","hide-details":"","dense":""},model:{value:(_vm.selectedProject),callback:function ($$v) {_vm.selectedProject=$$v},expression:"selectedProject"}}),_vm._v(" "),_vm._t("form-append")],2),_vm._v(" "),_c('v-btn',{staticClass:"ma-0 ml-2",attrs:{"color":"primary","disabled":invalid},on:{"click":_vm.generateLink}},[_vm._v("\n            Generate\n          ")])],1)],1):_c('v-card-text',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.uploadObject),expression:"uploadObject"}]},[_vm._v("\n          Project ID: "+_vm._s(_vm.uploadObject.projectId)+" "),_c('br'),_vm._v("\n          Token: "+_vm._s(_vm.uploadObject.token ? _vm.uploadObject.token : "None")+"\n        ")])]),_vm._v(" "),(
          _vm.ingestUploadType == _vm.ingestUploadTypes.droneUploads &&
          !invalid &&
          _vm.uploadObject
        )?_c('DroneUploadDialog',{attrs:{"project-id":_vm.uploadObject.projectId,"token":_vm.uploadObject.token},on:{"upload-completed":function($event){return _vm.onUploadCompleted()}}}):_vm._e(),_vm._v(" "),(
          _vm.ingestUploadType == _vm.ingestUploadTypes.bimUploads &&
          !invalid &&
          _vm.uploadObject
        )?_c('BIMUploadDialog',{attrs:{"id":_vm.uploadObject.id,"project-id":_vm.uploadObject.projectId,"project-name":_vm.uploadObject.name},on:{"upload-completed":function($event){return _vm.onUploadCompleted()}}}):_vm._e(),_vm._v(" "),(
          _vm.ingestUploadType == _vm.ingestUploadTypes._360Assets &&
          !invalid &&
          _vm.uploadObject
        )?_c('Assets360Dialog',{attrs:{"id":_vm.uploadObject.id,"upload-type":_vm.uploadObject.uploadType},on:{"upload-completed":function($event){return _vm.onUploadCompleted()}}}):_vm._e()],1)]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }