<template>
  <v-tabs
    v-model="currentTab"
    :vertical="$vuetify.breakpoint.mdAndUp"
    :show-arrows="$vuetify.breakpoint.smAndDown"
    class="ingest__tabs custom-tabs"
    active-class="blue lighten-5"
  >
    <v-tab
      v-for="(tab, index) in tabs"
      :key="`${index}${tab.name}head`"
      class="mx-0 px-6"
    >
      {{ tab.name }}
    </v-tab>
    <v-tab-item
      v-for="(tab, index) in tabs"
      :key="`${index}${tab.name}`"
      class="pa-0"
    >
      <component :is="tab.component" v-if="currentTab === index" />
    </v-tab-item>
  </v-tabs>
</template>

<script>
import DroneUpload from "@/components/ingest/tabs/DroneUpload"
import Assets360 from "@/components/ingest/tabs/Assets360"
import BIMUpload from "@/components/ingest/tabs/BimUpload"

export default {
  name: "Ingest",
  components: {
    DroneUpload,
    Assets360,
    BIMUpload,
  },
  data() {
    return {
      tabs: [
        {
          component: "DroneUpload",
          name: "Drone",
        },
        {
          component: "Assets360",
          name: "360",
        },
        {
          component: "BIMUpload",
          name: "BIM",
        },
      ],
    }
  },
  computed: {
    currentTab: {
      get() {
        return +this.$route.query.tab
      },
      set(value) {
        this.$router.push({
          path: this.$route.path,
          query: { tab: value },
        })
      },
    },
  },
}
</script>

<style lang="scss">
.ingest {
  &__tabs {
    & > .v-tabs-bar {
      border-right: 1px solid #e0e0e0;
      box-shadow: 5px 0px 11px -12px #000000b5;
      z-index: 1;
    }
    & > .v-tabs-items {
      overflow-y: hidden;
      max-height: 100vh;
    }
    .v-tab {
      text-transform: none;
    }
  }
}
</style>
