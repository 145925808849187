<template>
  <ERow no-gutters class="ma-2" justify="start" align="start">
    <div class="archive" @click="initDownloadArchive">
      <span class="mr-2">{{ dataType }}.zip</span>
      <v-icon color="primary" small> fa-solid fa-download </v-icon>
    </div>
  </ERow>
</template>

<script>
export default {
  props: {
    dataId: {
      type: Number,
      default: 0,
    },
    dataType: {
      type: String,
      default: "images",
    },
    downloadArchive: {
      type: Function,
      default: async () => {
        console.error(
          "<ArchiveDownloadLabel /> expects a 'download-archive' function for downloading an archived file"
        )

        return {
          data: "",
        }
      },
    },
  },
  methods: {
    async initDownloadArchive() {
      try {
        const link = await this.downloadArchive(this.dataId, {
          data_type: this.dataType,
        })
        if (link) {
          window.open(link, "_blank")
        } else {
          this.$notifications.error(
            "No link or data found to download this file"
          )
        }
      } catch (e) {
        this.$notifications.error(
          "An error occurred while getting the ip file",
          e
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.archive {
  cursor: pointer;
}
</style>
