import {
  BooleanOptions,
  IngestProcessingStatuses,
} from "@/components/constants"
export const DRONE_FILTERS = {
  projectId: {
    component: "TextFieldSearchFilter",
  },
  consumed: {
    component: "SelectSearchFilter",
    attributes: {
      items: [{ name: "All", value: "" }, ...BooleanOptions],
      value: "",
    },
  },
  processingStatus: {
    component: "SelectSearchFilter",
    attributes: {
      items: [{ name: "All", value: "" }, ...IngestProcessingStatuses],
      value: "",
    },
  },
  token: {
    component: "TextFieldSearchFilter",
  },
}

export const FILTERS_360 = {
  projectId: {
    component: "TextFieldSearchFilter",
  },
  consumed: {
    component: "SelectSearchFilter",
    attributes: {
      items: [{ name: "All", value: "" }, ...BooleanOptions],
      value: "",
    },
  },
  isAdmin: {
    component: "SelectSearchFilter",
    attributes: {
      items: [{ name: "All", value: "" }, ...BooleanOptions],
      value: "",
    },
  },
  token: {
    component: "TextFieldSearchFilter",
  },
}

export const FILTERS_ASSETS_360 = {
  id: {
    component: "TextFieldSearchFilter",
  },
  projectId: {
    component: "TextFieldSearchFilter",
  },
  date: {
    component: "TextFieldSearchFilter",
  },
  floor: {
    component: "TextFieldSearchFilter",
  },
}

export const FILTERS_BIM = {
  projectId: {
    component: "TextFieldSearchFilter",
  },
  id: {
    component: "TextFieldSearchFilter",
  },
  uploadedBy: {
    component: "TextFieldSearchFilter",
  },
}
