<template>
  <ERow id="upload-panel" no-gutters justify="center">
    <ECol class="ma-2">
      <FileBox
        :allowedTypes="allowedTypes"
        :disabled="
          ingestFileUploaderStore.uploadStats &&
          ingestFileUploaderStore.uploadStats.isUploading
        "
      />

      <v-card-actions class="my-2">
        <v-spacer />
        <v-btn
          color="white grey--text"
          elevation="0"
          :disabled="
            ingestFileUploaderStore.uploadStats &&
            ingestFileUploaderStore.uploadStats.isUploading
          "
          @click="clearForm()"
        >
          Clear
        </v-btn>
        <v-btn
          color="primary"
          :disabled="
            areFilesEmpty ||
            (ingestFileUploaderStore.uploadStats &&
              ingestFileUploaderStore.uploadStats.isUploading)
          "
          @click="processUpload"
        >
          Upload
        </v-btn>
      </v-card-actions>
    </ECol>
  </ERow>
</template>

<script>
import { ALLOWED_BIM_TYPES } from "@evercam/shared/constants/ingest"
import FileBox from "@/components/ingest/FileBox"
import * as tus from "tus-js-client/dist/tus.min.js"
import { IngestApi } from "@evercam/shared/api/ingestApi"
import { useIngestFileUploaderStore } from "@/stores/ingestFileUploader"
import { mapStores } from "pinia"

export default {
  components: {
    FileBox,
  },
  props: {
    id: {
      type: Number,
      default: -1,
    },
    projectId: {
      type: String,
      default: "",
    },
    projectName: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    uploadedUrls: [],
    allowedTypes: ALLOWED_BIM_TYPES,
  }),
  computed: {
    ...mapStores(useIngestFileUploaderStore),
    areFilesEmpty() {
      return this.ingestFileUploaderStore.files.length === 0
    },
  },
  mounted() {
    this.ingestFileUploaderStore.selectedFileType = "images"
    this.ingestFileUploaderStore.files = []
  },
  methods: {
    async processUpload() {
      if (this.areFilesEmpty) {
        this.$notifications.error("Please provide files to upload")

        return
      }

      try {
        this.ingestFileUploaderStore.uploadStats.isUploading = true
        this.ingestFileUploaderStore.uploadStats.percentage = 0
        for (let i = 0; i < this.ingestFileUploaderStore.files.length; i++) {
          this.uploadedUrls.push(
            await this.uploadFile(this.ingestFileUploaderStore.files[i])
          )
        }
        this.uploadToPortal()
      } catch (e) {
        this.$notifications.error(`Couldn't upload files : ${e}`)
        this.ingestFileUploaderStore.uploadStats = null
      }
    },
    async uploadFile(file) {
      return new Promise((resolve, reject) => {
        const upload = new tus.Upload(file, {
          endpoint: `${this.$config.public.portalURL}`,
          chunkSize: Infinity,
          retryDelays: [0, 1000, 3000, 5000],
          parallelUploads: 1,
          metadata: {
            filename: file.name,
            filetype: file.type,
          },
          onProgress: (bytesUploaded) => {
            this.ingestFileUploaderStore.uploadStats.percentage = (
              ((this.ingestFileUploaderStore.uploadStats.uploadedSize +
                bytesUploaded) /
                this.ingestFileUploaderStore.uploadStats.totalSize) *
              100
            ).toFixed(2)
          },
          onSuccess: () => {
            this.ingestFileUploaderStore.uploadStats.uploadedSize += file.size
            this.ingestFileUploaderStore.uploadStats.percentage = (
              (this.ingestFileUploaderStore.uploadStats.uploadedSize /
                this.ingestFileUploaderStore.uploadStats.totalSize) *
              100
            ).toFixed(2)

            file.uploadPercentage = "100"
            const [title, fileExtension] = upload.file.name.split(".")
            resolve({
              url: upload.url,
              title,
              fileExtension,
            })
          },
          onError: (error) => {
            console.log("Failed because: " + error)
            reject(error)
          },
        })
        upload.start()
      })
    },
    async uploadToPortal() {
      this.ingestFileUploaderStore.uploadStats.isProcessing = true
      this.ingestFileUploaderStore.uploadStats.isUploading = false
      try {
        await IngestApi.bim.upload(
          this.projectId,
          {
            id: this.id,
            name: this.projectName,
          },
          {
            upload: this.uploadedUrls,
          }
        )
      } catch (e) {
        this.$notifications.error({ text: "Error uploading images", error: e })
      } finally {
        this.ingestFileUploaderStore.uploadStats = {
          percentage: 0,
          totalSize: 0,
          uploadedSize: 0,
          isUploading: false,
          isProcessing: false,
        }
        this.uploadedUrls = []
        this.$emit("upload-completed")
      }
    },
    clearForm() {
      this.ingestFileUploaderStore.files = []
      this.floor = ""
      this.date = ""
    },
  },
}
</script>

<style lang="scss">
#upload-panel {
  z-index: 2 !important;
}
</style>
