export const DRONE_HEADER = [
  {
    value: "id",
    text: "ID",
    width: 20,
  },
  {
    value: "projectId",
    text: "Project ID",
    width: 40,
  },
  {
    value: "consumed",
    text: "Processed",
    width: 20,
  },
  {
    value: "processingStatus",
    text: "Processing Status",
    width: 120,
  },
  {
    value: "uploadedBy",
    text: "Uploaded By",
    width: 20,
  },
  {
    value: "flightDate",
    text: "Flight Date",
    width: 40,
  },
  {
    value: "flightGeolocation",
    text: "Flight Location",
    width: 40,
  },
  {
    value: "modelZip",
    text: "Model",
    width: 40,
  },
  {
    value: "orthomosaicZip",
    text: "Orthomosaic",
    width: 40,
  },
  {
    value: "reportPdf",
    text: "Report",
    width: 40,
  },
  {
    value: "updatedAt",
    text: "Updated at",
    width: 40,
  },
  {
    value: "delete",
    text: "",
    width: 40,
  },
]

export const HEADERS_360 = [
  {
    value: "id",
    text: "id",
    width: 30,
  },
  {
    value: "projectId",
    text: "Project ID",
    width: 40,
  },
  {
    value: "uploadedBy",
    text: "Uploaded By",
    width: 20,
  },
  {
    value: "date",
    text: "Walk Date",
    width: 40,
  },
  {
    value: "floor",
    text: "Floor",
    width: 40,
  },
  {
    value: "videos",
    text: "Videos",
    width: 40,
  },
  {
    value: "images",
    text: "Images",
    width: 40,
  },
  {
    value: "model",
    text: "Model",
    width: 40,
  },
  {
    value: "route",
    text: "Route",
    width: 40,
  },
  {
    value: "mobilePhotos",
    text: "Mobile photos",
    width: 40,
  },
  {
    value: "mobileAudios",
    text: "Mobile audios",
    width: 40,
  },
  {
    value: "updatedAt",
    text: "Updated at",
    width: 80,
  },
  {
    value: "delete",
    text: "",
    width: 40,
  },
]

export const BIM_HEADER = [
  {
    value: "link",
    text: "",
    width: 20,
  },
  {
    value: "id",
    text: "ID",
    width: 20,
  },
  {
    value: "projectId",
    text: "Project ID",
    width: 40,
  },
  {
    value: "uploadedBy",
    text: "Uploaded By",
    width: 20,
  },
  {
    value: "files",
    text: "Files",
  },
  {
    value: "updatedAt",
    text: "Updated at",
    width: 80,
  },
  {
    value: "delete",
    text: "",
    width: 40,
  },
]
