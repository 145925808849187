<template>
  <v-dialog :value="uploadDialog" width="500">
    <ValidationObserver v-slot="{ invalid }">
      <v-card>
        <v-card-title>
          {{ title }}
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog">
            <v-icon color="primary" large> fas fa-times </v-icon>
          </v-btn>
        </v-card-title>

        <v-divider />

        <v-card-text v-if="!uploadObject">
          <ERow no-gutters justify="between" align-content="center">
            <ECol class="pa-0">
              <Autocomplete
                v-model="selectedProject"
                item-value="id"
                item-text="name"
                clearable
                label="Project"
                no-filter
                :cache-items="false"
                resource="projects"
                rules="required"
                hide-details
                dense
              />
              <slot name="form-append"></slot>
            </ECol>
            <v-btn
              class="ma-0 ml-2"
              color="primary"
              :disabled="invalid"
              @click="generateLink"
            >
              Generate
            </v-btn>
          </ERow>
        </v-card-text>
        <v-card-text v-else>
          <div v-show="uploadObject">
            Project ID: {{ uploadObject.projectId }} <br />
            Token: {{ uploadObject.token ? uploadObject.token : "None" }}
          </div>
        </v-card-text>

        <DroneUploadDialog
          v-if="
            ingestUploadType == ingestUploadTypes.droneUploads &&
            !invalid &&
            uploadObject
          "
          :project-id="uploadObject.projectId"
          :token="uploadObject.token"
          @upload-completed="onUploadCompleted()"
        />

        <BIMUploadDialog
          v-if="
            ingestUploadType == ingestUploadTypes.bimUploads &&
            !invalid &&
            uploadObject
          "
          :id="uploadObject.id"
          :project-id="uploadObject.projectId"
          :project-name="uploadObject.name"
          @upload-completed="onUploadCompleted()"
        />
        <Assets360Dialog
          v-if="
            ingestUploadType == ingestUploadTypes._360Assets &&
            !invalid &&
            uploadObject
          "
          :id="uploadObject.id"
          :upload-type="uploadObject.uploadType"
          @upload-completed="onUploadCompleted()"
        />
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import Autocomplete from "@evercam/shared/components/Autocomplete"
import DroneUploadDialog from "@/components/ingest/uploadDialogs/DroneUploadDialog"
import BIMUploadDialog from "@/components/ingest/uploadDialogs/BimUploadDialog"
import Assets360Dialog from "@/components/ingest/uploadDialogs/360AssetsDialog"
import { INGEST_UPLOADS_TYPES } from "@evercam/shared/constants/ingest"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"

export default {
  components: {
    Autocomplete,
    DroneUploadDialog,
    BIMUploadDialog,
    Assets360Dialog,
  },
  props: {
    uploadDialog: {
      type: Boolean,
      default: false,
    },
    ingestUploadType: {
      type: String,
      default: "",
    },
    provider: {
      type: Function,
      default: () => {},
    },
    uploadObject: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    selectedProject: null,
    ingestUploadTypes: INGEST_UPLOADS_TYPES,
  }),
  computed: {
    ...mapStores(useAccountStore),
    title() {
      if (this.uploadObject) {
        if (this.ingestUploadType === INGEST_UPLOADS_TYPES._360Assets) {
          return `Upload ${this.uploadObject.uploadType} ${this.ingestUploadType}`
        } else {
          return `Upload ${this.uploadObject.uploadType}`
        }
      } else {
        return `New ${this.ingestUploadType}`
      }
    },
  },
  methods: {
    async generateLink() {
      if (!this.selectedProject) {
        return
      }
      await this.provider({
        project: this.selectedProject,
        uploadedBy: this.accountStore.email,
      })
    },
    closeDialog() {
      this.$emit("close-dialog")
    },
    onUploadCompleted() {
      this.$root.$emit("refresh-report-table")
      this.closeDialog()
    },
  },
}
</script>
